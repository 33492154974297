import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
// 导入自定义工具 axios-request
import request from '@/utils/request.js'
import VueAxios from 'vue-axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/vender/elementTheme/screenTable.css'
import * as echarts from 'echarts'
import '@/vender/iconfont/iconfont.css'
import '@/vender/iconfont/iconfont.js'
import '@/assets/css/iconfont.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueAxios, request)
app.use(ElementPlus)
app.config.globalProperties.$echarts = echarts
app.mount('#app')
