<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/scxtxyjkzt">生产线通讯与健康状态</router-link>
  </nav> -->
  <router-view/>
</template>

<script>
export default {
}
</script>

<style lang="less">
</style>
