import axios from 'axios'
const serverUrl = '/fsdp/ty/bwzsyth'
const servertimeout = 5000

const request = axios.create({
  // 指定请求的根路径
  baseURL: serverUrl,
  // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
  // 如果请求话费了超过 `timeout` 的时间，请求将被中断
  timeout: servertimeout,
  // `headers` 是即将被发送的自定义请求头
  headers: {
    'Content-Type': 'application/json;',
    'X-Requested-With': 'XMLHttpRequest'
  },
  // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
  responseType: 'json',
  // `withCredentials` 表示跨域请求时是否需要使用凭证
  withCredentials: true // 默认的
})
export default request
